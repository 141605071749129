<template>
    <div class='Feedback'>
          
    </div>
</template>

<script>
  export default {
    name:'Feedback',
     components: {},
     data() {
        return {

        };
     },
     computed: {},
     watch: {},
     methods: {
         
     },
     created() {
         
     },
     mounted() {
         
     },
  }
</script>

<style lang='less' scoped>
    
</style>